window.addEventListener('load', function () {
  const videoButton = document.querySelector('.vitec-media-button');
  const videoModal = document.querySelector('.video-modal');
  const modalBackground = document.querySelector('.modal-background');
  const video = document.querySelector('video');
  const bodyScrollLock = require('body-scroll-lock');
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;

  if (videoButton) {
    videoButton.addEventListener('click', (event) => {
      videoModal.classList.add('show');
      disableBodyScroll(videoModal);
      video.play();
    });
  }

  if (modalBackground) {
    modalBackground.addEventListener('click', (event) => {
      videoModal.classList.remove('show');
      enableBodyScroll(videoModal);
      video.pause();
    });
  }
});
